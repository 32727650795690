<template>
  <div>
    <div class="mt-2">
      <div style="min-height: 4px;">
        <v-progress-linear v-model="value" :active="show" :indeterminate="query" :query="true"></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressLinear",

  data: () => ({
    value: 0,
    query: false,
    show: true,
    interval: 0,
  }),
  mounted () {
    this.queryAndIndeterminate()
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    queryAndIndeterminate () {
      this.query = true
      this.show = true
      this.value = 0

      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = false
            return setTimeout(this.queryAndIndeterminate, 2000)
          }
          this.value += 10
        }, 1000)
      }, 2500)
    },
  },
};
</script>