<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pt-0">
      <v-toolbar dense flat class="transparent mb-1" height="40">
        <v-toolbar-title>
          <span class="info--text">{{ $t(title) }}</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: "AdpBreadcrumb",
  props: {
    title: String,
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.page-breadcrumb {
  .v-toolbar__content {
    padding: 0px;
  }
}
</style>
