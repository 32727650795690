import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/vuetify/overrides.scss'
Vue.use(Vuetify);

var theme = {
    primary: '#1e88e5',
    info: '#1e88e5',
    success: '#21c1d6',
    accent: '#fc4b6c',
    default: '#563dea',
    spreadsheet: '#4f9d49'
}

if (process.env.VUE_APP_INSTANCE == "hdh") {
    theme['avgidea'] = '#ffffff';
} else {
    theme['avgidea'] = '#e89a37';
}

export default new Vuetify({
    theme: {
        themes: {
          dark: theme,
          light: theme,
        },
      }
});
