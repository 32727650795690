import Vue from 'vue'
import { firestorePlugin } from 'vuefire'

/*import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";*/

import firebase from 'firebase/app'



Vue.use(firestorePlugin)
export var firebaseConfig = {
    apiKey: process.env.VUE_APP_ADP_APIKEY,
    authDomain: process.env.VUE_APP_ADP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_ADP_DATABASEURL,
    projectId: process.env.VUE_APP_ADP_PROJECTID,
    storageBucket: process.env.VUE_APP_ADP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_ADP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_ADP_APPID,
    measurementId: process.env.VUE_APP_ADP_MEASUREMENTID
};

// export const app = initializeApp(firebaseConfig);
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

/*export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); */
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
// export const analytics = firebase.analytics();

