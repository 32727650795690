<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Refresh the ID token</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div align="right">
              <v-btn text @click="refresh()">Refresh</v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue"
import firebase from "firebase"
import {eh, EventBus} from "@/plugins/eventhandler";

export default {
  name: "TokenDialog",
  data: () => ({
    dialog: false,
  }),
  mounted() {
    var p = this;
    eh.on("token", function(value) {
      p.dialog = value;
    });
  },
  methods: {
    visible(status) {
      this.dialog = status;
    },
    async refresh() {
      var u = firebase.auth().currentUser;
      var p = this;
      await u.getIdToken(true).then(async function (idToken) {
        console.log("Token refreshed")
        EventBus.$emit("alert", {mode: "success", status: true, text: "Token refreshed"});
        Vue.prototype.$token.fa = idToken;
        p.dialog = false;
      });
    }
  }
}
</script>
<style scoped>

</style>