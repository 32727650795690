<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <TextDialog ref="text"></TextDialog>
    <TokenDialog ref="tokendlg"></TokenDialog>
  </v-app>

</template>

<script>
import ConfirmDialog from "@/components/ui/ConfirmDialog";
import TextDialog from "./components/ui/TextDialog";
import TokenDialog from "./components/ui/TokenDialog";
import {eh} from "@/plugins/eventhandler";

export default {
  name: 'App',
  components: {
    ConfirmDialog,
    TextDialog,
    TokenDialog,
  },
  created() {
    document.title = process.env.VUE_APP_NAME;
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$text = this.$refs.text.open;
    this.$root.$tokendlg = this.$refs.tokendlg.visible;
    var p = this;
    eh.on("passcode", function() {
      p.$router.push("/passcode").catch(() => {});
    });
    this.activateMultipleDraggableDialogs();
  }
};
</script>
