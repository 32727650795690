<template>
  <v-alert v-if="status" dense text :type="mode">{{text}}</v-alert>
</template>
<script>
import {eh} from "@/plugins/eventhandler"

export default {
  name: "Alert",
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    status: null,
    mode: null,
    text: null,
  }),
  watch: {
    page(value) {
      this.text = value.text;
      this.status = value.status;
      this.mode = value.mode;
    }
  },
  mounted() {
    this.status = this.page.status;
    this.mode = this.page.mode;
    this.text = this.page.text;
    var p = this;
    eh.on("alert", function(update) {
      p.status = update.status;
      p.mode = update.mode;
      p.text = update.text;
    });
  },
  methods: {}
}
</script>
<style scoped>
    .v-alert {
        font-size: 14px;
        padding: 10px;
    }
</style>