<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :max-width=options.width>
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div v-if="text != null" class="body-2" style="white-space: pre-wrap;">
              {{text}}
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "TextDialog",
  data: () => ({
    title: null,
    text: null,
    dialog: false,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200,
      selection: true
    }
  }),
  methods: {
    open(title, text) {
      this.dialog = true
      this.title = title
      this.text = text
    },
  }
}
</script>
<style scoped>

</style>