import Vue from "vue";

export const EventBus = new Vue();

var m = {};

class eventhandler {
    on(name, handler) {
        if (m[name] == undefined) {
            m[name] = true;
        } else {
            EventBus.$off(name);
        }
        EventBus.$on(name, handler);
    }

    off(name) {
        EventBus.$off(name);
    }
}

export const eh = new eventhandler();

